<template>
  <task-dashboard />
</template>

<script>
const TaskDashboard = () => import('@/components/Machines/TaskDashboard')

export default {
  name: 'TaskDashboardView',
  components: {
    TaskDashboard
  }
}
</script>
